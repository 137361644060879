export const SendIcon = (props: any) => {
  return (
    <div {...props}>
      <svg
        width="27"
        height="28"
        viewBox="0 0 27 28"
        fill="#D4D4D8"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M0.846085 0.94909C1.25151 0.632544 1.80286 0.57743 2.26292 0.807461L26.2629 12.8075C26.7146 13.0333 27 13.495 27 14C27 14.5051 26.7146 14.9667 26.2629 15.1926L2.26292 27.1926C1.80286 27.4226 1.25151 27.3675 0.846084 27.051C0.44066 26.7344 0.25347 26.2129 0.365051 25.7108L2.96744 14L0.365052 2.28927C0.253471 1.78716 0.440661 1.26564 0.846085 0.94909ZM5.40286 15.3334L3.5759 23.5547L22.6852 14L3.5759 4.44538L5.40286 12.6667H15C15.7363 12.6667 16.3333 13.2637 16.3333 14C16.3333 14.7364 15.7363 15.3334 15 15.3334H5.40286Z"
          fill="currentcolor"
        />
      </svg>
    </div>
  );
};
