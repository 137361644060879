export const OpenEyesIcon = (props: any) => {
  return (
    <div {...props}>
      <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
          d="M1.92653 7.00001C2.95629 9.91383 5.73556 12 9.00002 12C12.2645 12 15.0437 9.91385 16.0735 7.00007C15.0437 4.08625 12.2645 2.00004 9 2.00004C5.73556 2.00004 2.95631 4.08623 1.92653 7.00001ZM0.253137 6.7503C1.42098 3.03206 4.89425 0.333374 9 0.333374C13.1058 0.333374 16.5791 3.03209 17.7469 6.75037C17.7979 6.91292 17.7979 7.08723 17.7469 7.24978C16.579 10.968 13.1058 13.6667 9.00002 13.6667C4.89424 13.6667 1.42096 10.968 0.253135 7.24972C0.202079 7.08716 0.20208 6.91286 0.253137 6.7503ZM8.99963 5.33337C8.07916 5.33337 7.33297 6.07957 7.33297 7.00004C7.33297 7.92052 8.07916 8.66671 8.99963 8.66671C9.92011 8.66671 10.6663 7.92052 10.6663 7.00004C10.6663 6.07957 9.92011 5.33337 8.99963 5.33337ZM5.6663 7.00004C5.6663 5.15909 7.15868 3.66671 8.99963 3.66671C10.8406 3.66671 12.333 5.15909 12.333 7.00004C12.333 8.84099 10.8406 10.3334 8.99963 10.3334C7.15868 10.3334 5.6663 8.84099 5.6663 7.00004Z"
          fill="#A1A1AA"/>
      </svg>
    </div>
  );
};