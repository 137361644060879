export const ContactIcon = (props: any) => {
  return (
    <div {...props}>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Basic / Adress Book">
          <path
            id="Shape"
            d="M13 4C9.68629 4 7 6.68629 7 10V38C7 41.3137 9.68629 44 13 44H35C38.3137 44 41 41.3137 41 38V10C41 6.68629 38.3137 4 35 4H13ZM11 10C11 8.89543 11.8954 8 13 8H35C36.1046 8 37 8.89543 37 10V38C37 39.1046 36.1046 40 35 40H13C11.8954 40 11 39.1046 11 38V10ZM27.6 18.6C27.6 20.5882 25.9882 22.2 24 22.2C22.0118 22.2 20.4 20.5882 20.4 18.6C20.4 16.6118 22.0118 15 24 15C25.9882 15 27.6 16.6118 27.6 18.6ZM15.7905 31.2131C15.58 32.1847 16.4059 33 17.4 33H30.6C31.5941 33 32.42 32.1847 32.2095 31.2131C31.3905 27.4323 28.026 24.6 24 24.6C19.974 24.6 16.6096 27.4323 15.7905 31.2131Z"
            fill="#A1A1AA"
          />
        </g>
      </svg>
    </div>
  );
};
