import React from 'react';

export const CloseEyesIcon = (props: any) => {
  return (
    <div {...props}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.910378 0.910704C1.23581 0.585267 1.76345 0.585267 2.08889 0.910704L4.62247 3.44428C5.92377 2.73579 7.41584 2.33329 9 2.33329C13.1058 2.33329 16.5791 5.03201 17.7469 8.75028C17.7979 8.91284 17.7979 9.08714 17.7469 9.2497C17.203 10.9815 16.16 12.4904 14.7873 13.6091L17.0889 15.9107C17.4143 16.2361 17.4143 16.7638 17.0889 17.0892C16.7635 17.4147 16.2358 17.4147 15.9104 17.0892L0.910378 2.08922C0.584941 1.76378 0.584941 1.23614 0.910378 0.910704ZM5.86333 4.68515L7.30645 6.12826C7.80265 5.8352 8.38181 5.66663 8.99963 5.66663C10.8406 5.66663 12.333 7.15901 12.333 8.99996C12.333 9.61778 12.1644 10.1969 11.8713 10.6931L13.6013 12.4231C14.7238 11.5498 15.591 10.3649 16.0735 8.99998C15.0437 6.08617 12.2645 3.99996 9 3.99996C7.87921 3.99996 6.81715 4.24532 5.86333 4.68515ZM10.61 9.4318C10.6467 9.29414 10.6663 9.14942 10.6663 8.99996C10.6663 8.07948 9.92011 7.33329 8.99963 7.33329C8.85018 7.33329 8.70546 7.35285 8.5678 7.38961L10.61 9.4318ZM2.8537 5.81144C3.2207 6.08917 3.29306 6.61182 3.01533 6.97882C2.55483 7.58732 2.18532 8.26774 1.92653 8.99994C2.9563 11.9138 5.73556 14 9.00002 14C9.48154 14 9.95172 13.9547 10.4068 13.8684C10.8589 13.7827 11.295 14.0797 11.3808 14.5319C11.4665 14.9841 11.1695 15.4201 10.7173 15.5059C10.1604 15.6115 9.58626 15.6666 9.00002 15.6666C4.89424 15.6666 1.42096 12.9679 0.253135 9.24964C0.202079 9.08708 0.20208 8.91278 0.253137 8.75022C0.571397 7.73693 1.06055 6.79997 1.68633 5.97307C1.96406 5.60608 2.48671 5.53371 2.8537 5.81144Z"
          fill="#A1A1AA"
        />
      </svg>
    </div>
  );
};
