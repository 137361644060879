import React from 'react';

export const LoginErrorIcon = (props: any) => {
  return (
    <div {...props}>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.3996 6.99998C13.3996 10.5346 10.5342 13.4 6.99961 13.4C3.46499 13.4 0.599609 10.5346 0.599609 6.99998C0.599609 3.46535 3.46499 0.599976 6.99961 0.599976C10.5342 0.599976 13.3996 3.46535 13.3996 6.99998ZM7.79961 10.2C7.79961 10.6418 7.44144 11 6.99961 11C6.55778 11 6.19961 10.6418 6.19961 10.2C6.19961 9.75815 6.55778 9.39998 6.99961 9.39998C7.44144 9.39998 7.79961 9.75815 7.79961 10.2ZM6.99961 2.99998C6.55778 2.99998 6.19961 3.35815 6.19961 3.79998V6.99998C6.19961 7.4418 6.55778 7.79998 6.99961 7.79998C7.44144 7.79998 7.79961 7.4418 7.79961 6.99998V3.79998C7.79961 3.35815 7.44144 2.99998 6.99961 2.99998Z"
          fill="#EF4343"
        />
      </svg>
    </div>
  );
};
