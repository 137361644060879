export const LogoutIcon = (props: any) => {
  return (
    <div {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.66667 3.33333C3.93029 3.33333 3.33333 3.93029 3.33333 4.66667V11.3333C3.33333 12.0697 3.93029 12.6667 4.66667 12.6667H7.33333C8.06971 12.6667 8.66667 12.0697 8.66667 11.3333V10.6667C8.66667 10.2985 8.96514 10 9.33333 10C9.70152 10 10 10.2985 10 10.6667V11.3333C10 12.8061 8.80609 14 7.33333 14H4.66667C3.19391 14 2 12.8061 2 11.3333V4.66667C2 3.19391 3.19391 2 4.66667 2H7.33333C8.80609 2 10 3.19391 10 4.66667V5.33333C10 5.70152 9.70152 6 9.33333 6C8.96514 6 8.66667 5.70152 8.66667 5.33333V4.66667C8.66667 3.93029 8.06971 3.33333 7.33333 3.33333H4.66667ZM11.5286 4.86193C11.7889 4.60158 12.2111 4.60158 12.4714 4.86193L15.1381 7.5286C15.3984 7.78895 15.3984 8.21106 15.1381 8.4714L12.4714 11.1381C12.2111 11.3984 11.7889 11.3984 11.5286 11.1381C11.2682 10.8777 11.2682 10.4556 11.5286 10.1953L13.0572 8.66667L5.33333 8.66667C4.96514 8.66667 4.66667 8.36819 4.66667 8C4.66667 7.63181 4.96514 7.33333 5.33333 7.33333L13.0572 7.33333L11.5286 5.80474C11.2682 5.54439 11.2682 5.12228 11.5286 4.86193Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
