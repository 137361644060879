export const TipIcon = (props: any) => (
  <div {...props}>
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.50008 2.33332C4.81818 2.33332 1.83341 5.31809 1.83341 8.99999C1.83341 12.6819 4.81818 15.6667 8.50008 15.6667C12.182 15.6667 15.1667 12.6819 15.1667 8.99999C15.1667 5.31809 12.182 2.33332 8.50008 2.33332ZM0.166748 8.99999C0.166748 4.39762 3.89771 0.666656 8.50008 0.666656C13.1025 0.666656 16.8334 4.39762 16.8334 8.99999C16.8334 13.6024 13.1025 17.3333 8.50008 17.3333C3.89771 17.3333 0.166748 13.6024 0.166748 8.99999ZM7.66675 5.66666C7.66675 5.20642 8.03984 4.83332 8.50008 4.83332H8.50842C8.96865 4.83332 9.34175 5.20642 9.34175 5.66666C9.34175 6.12689 8.96865 6.49999 8.50842 6.49999H8.50008C8.03984 6.49999 7.66675 6.12689 7.66675 5.66666ZM6.83342 8.99999C6.83342 8.53975 7.20651 8.16666 7.66675 8.16666H8.50008C8.96032 8.16666 9.33342 8.53975 9.33342 8.99999V11.5C9.79365 11.5 10.1667 11.8731 10.1667 12.3333C10.1667 12.7936 9.79365 13.1667 9.33342 13.1667H8.50008C8.03984 13.1667 7.66675 12.7936 7.66675 12.3333V9.83332C7.20651 9.83332 6.83342 9.46023 6.83342 8.99999Z"
        fill="#71717A"
      />
    </svg>
  </div>
);
